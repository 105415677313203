<template>
  <div>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column
        sortable
        prop="link"
        label="Link"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column sortable prop="user" label="User"></el-table-column>
      <el-table-column label="Operations">
        <template slot-scope="scope">
          <el-button-group>
            <el-button
              icon="el-icon-edit"
              @click="update(scope.row, scope.$index)"
            ></el-button>
            <el-button
              icon="el-icon-delete"
              @click="remove(scope.$index)"
            ></el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>

    <el-drawer
      title="Credential bank"
      :visible.sync="component.drawer"
      direction="rtl"
      size="500px"
    >
      <div class="pt-3 pl-5 pr-5 pb-5">
        <component-credential-bank
          v-on:refresh="load($event)"
          :data="component.data"
          :key="component.render"
        />
      </div>
    </el-drawer>
    <el-button class="fixed-bottom new-register" type="success" @click="add"
      >Add new register</el-button
    >
  </div>
</template>

<script>
import credentialBank from "@/services/api/credentialBank";

import ComponentCredentialBank from "@/components/admin/client/CredentialBank";

export default {
  components: {
    ComponentCredentialBank,
  },
  data() {
    return {
      tableData: [],
      component: {
        data: null,
        drawer: false,
        render: 0,
      },
      index: null,
    };
  },
  mounted() {
    credentialBank.getById(this.$route.params.id).then((response) => {
      this.tableData = response;
    });
  },
  methods: {
    load(event) {
      this.component.drawer = false;
      //
      if (event.update) {
        this.tableData.splice(this.index, 1, event.data);
      } else {
        this.tableData.unshift(event.data);
      }
    },
    add() {
      this.component.drawer = true;
      this.component.render += 1;
      this.component.data = null;
    },
    update(row, index) {
      this.index = index;
      //
      this.component.drawer = true;
      this.component.render += 1;
      this.component.data = row;
    },
    remove(index) {
      this.tableData.splice(index, 1);
    },
  },
};
</script>
